import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SideNavigation as SideNavigationCompass } from '@syneto/compass-react';
import { ServiceContext } from '../contexts/ServiceContext';
import { StoreContext } from '../contexts/StoreContext';
import Image from '../assets/img/syneto.svg';
import Logo from '../assets/img/syneto-icon.svg';

export const SideNavigation = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { accessService, restService } = useContext(ServiceContext);
	const { state } = useContext(StoreContext);
	const { services } = state;

	const [showIsUpgradableToOs5Button, setShowIsUpgradableToOs5Button] = useState(false);
	const [selectedMenuItem, setSelectedMenuItem] = useState('');

	if (state?.isAuthorized && state?.loggedInUser?.company?.id) {
		restService.getIsUpgradableToOs5(state.loggedInUser.company.id)
			.then((response) => {
				setShowIsUpgradableToOs5Button(response.data.isActive);
			});
	}

	useEffect(() => {
		setSelectedMenuItem(location.pathname.replace('/', ''));
	}, [location.pathname]);

	const getSideNavigationItems = () => {
		const items = [
			{
				eventKey: 'brand',
				brandImage: Image,
				brandLogo: Logo,
				brandTitle: 'Central',
			},
		];

		if (accessService.hasPermissionOnAnyResource('location.list')) {
			items.push({
				icon: 'fas fa-map-marked-alt',
				title: 'Dashboard',
				eventKey: 'dashboard',
			});
		}

		if (accessService.hasPermissionOnAnyResource('appliance.list')) {
			items.push(
				{
					icon: 'fas fa-server',
					title: 'Inventory',
					eventKey: 'inventory',
				},
				{
					icon: 'fas fa-bell',
					title: 'SerenITy Events',
					eventKey: 'serenity-events',
				}
			);
		}

		if (accessService.hasPermissionOnAnyResource('user.list')) {
			items.push({
				icon: 'fas fa-users',
				title: 'Users',
				eventKey: 'users',
			});
		}

		if (accessService.hasPermissionOnAnyResource('ras.list')) {
			// ras admins can view ras dashboard (when ras is active or expired), only after service activation date
			if (
				accessService.hasPermissionOnAnyResource('location.list') &&
        (services?.ras?.active || services?.ras?.expired)
			) {
				items.push({
					icon: 'fas fa-globe-europe',
					title: 'Remote Access',
					eventKey: 'ras',
				});
				// regular users can view ras config files (when ras is active), only after service activation date
			} else if (!accessService.hasPermissionOnAnyResource('location.list') && services?.ras?.active) {
				items.push({
					icon: 'fas fa-globe-europe',
					title: 'Remote Access',
					eventKey: 'ras',
				});
			}
		}

		return items;
	};

	const getSideNavigationBottomItems = () => {
		const items = [];

		if (showIsUpgradableToOs5Button) {
			items.push(
				{
					icon: 'fas fa-circle',
					title: 'Migrate to SynetoOS 5',
					eventKey: 'migrate-os',
				}
			);
		}

		if (accessService.hasPermissionOnAnyResource('appliance.list')) {
			items.push(
				{
					icon: 'fas fa-cube',
					title: 'Virtual Appliance',
					eventKey: 'virtual-appliance',
				},
				{
					icon: 'fas fa-headset',
					title: 'Support',
					eventKey: 'support',
				}
			);
		}

		return items;
	};

	return (
		<SideNavigationCompass
			selected={selectedMenuItem}
			onSelect={(selected) => {
				navigate(`/${selected}`);
			}}
			items={getSideNavigationItems()}
			bottomItems={getSideNavigationBottomItems()}
		/>
	);
};
